// src/App.js

import React from 'react';
import './App.css';
import CurrencyQuoter from './CurrencyQuoter'; // Import the CurrencyQuoter component
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className="App">
      <header>
        <div className="logo">Martinez</div>
      </header>
      <section className="main-content">
        <p>Bienvenido a Martinez.uy, el sitio está disponible para su compra.</p>
        <ul>
          <li>Nombre de Dominio: <strong>Martinez.uy</strong></li>
        </ul>
        <p>Contacto para más información:</p>
        <p>Email: <a href="mailto:jupam.18@outlook.com">jupam.18@outlook.com</a> <i className="fas fa-envelope envelope-icon"></i></p>
      </section>
      <div className="middle-content">
        <CurrencyQuoter /> {/* Include the CurrencyQuoter component */}
        <SpeedInsights />
        <Analytics />
      </div>
      <footer>
        <div className="running-man"></div>
        <p>&copy; 2024 Martinez.uy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default App;
