// src/CurrencyQuoter.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CurrencyQuoter = () => {
  const [exchangeRates, setExchangeRates] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        // Public API endpoint for ExchangeRate-API
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
        setExchangeRates(response.data.rates);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
        setError('Error fetching exchange rates');
        setLoading(false);
      }
    };

    fetchExchangeRates();
  }, []);

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  if (loading) {
    return <div>Loading exchange rates...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Conversion rate from selected currency to UYU
  const conversionRate = exchangeRates[selectedCurrency]
    ? (1 / exchangeRates[selectedCurrency] * exchangeRates['UYU']).toFixed(2)
    : 0;

  return (
    <div className="currency-quoter">
      <h2>Currency Converter</h2>
      <label htmlFor="currency-select">Select Currency:</label>
      <select id="currency-select" value={selectedCurrency} onChange={handleCurrencyChange}>
        {Object.keys(exchangeRates).map(currency => (
          <option key={currency} value={currency}>{currency}</option>
        ))}
      </select>
      <div>
        <h3>Conversion Rate to UYU</h3>
        <p>{selectedCurrency} to UYU: {conversionRate} UYU</p>
      </div>
    </div>
  );
};

export default CurrencyQuoter;
